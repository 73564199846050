import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.httpService';
import { URLConstants } from 'src/app/shared/utility/url-constants';
import {
  IManifestCriteriaData,
  IManifestInformationData,
} from '../model/manifest.model';
import { ModalService } from 'src/app/shared/services/modal.service';
import {
  CrudActions,
  ModalPopupMessages,
  validationErrorMessages,
} from 'src/app/shared/utility/constants';
import { MessagePopupComponent } from 'src/app/shared/components/message-popup/message-popup.component';
import { criteriaRequiredKeys } from '../components/manifest/manifest.config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ManifestService implements OnDestroy {
  public setCriteriaDestination = new BehaviorSubject<boolean>(false);
  public setManifestOrigin = new BehaviorSubject<boolean>(false);
  public manifestCriteriaFormData: any = {};
  public bagCriteriaFormData: any = {};
  private readonly destroy$ = new Subject<void>();
  public isManifestPageSummary = new BehaviorSubject<any>('');
  public isManifestCriteriaFormGroup = new BehaviorSubject<any>({});

  constructor(
    public baseService: BaseService,
    private readonly modalService: ModalService,
    private readonly http: HttpClient
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getBagDataByExt(requestType: string, data: any): Observable<any> {
    const url = `${URLConstants.getBagsForManifest}/${requestType}/${data}`;
    return this.baseService.initiateApiRequest('GET', url, '', 'json');
  }

  public createManifest(data: IManifestInformationData): Observable<any> {
    const url = URLConstants.createManifest;
    return this.baseService.initiateApiRequest('PUT', url, data, 'json');
  }

  public updateManifest(data: IManifestInformationData): Observable<any> {
    const url = URLConstants.updateManifest;
    return this.baseService.initiateApiRequest('POST', url, data, 'json');
  }

  public checkMawbForManifest(data: any) {
    const url = URLConstants.checkMawbExists;
    return this.baseService.initiateApiRequest('POST', url, data, 'json');
  }

  public checkFlightForManifest(data: any) {
    const url = URLConstants.checkFlightExists;
    return this.baseService.initiateApiRequest('POST', url, data, 'json');
  }

  public getSearchData(event: any): Observable<any> {
    const apiUrl = URLConstants.getManifestSearchList;
    return this.http.post(apiUrl, event);
  }

  public deleteManifests(event: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.deleteManifest,
      event,
      '',
      'json'
    );
  }

  public deleteManifestBags(event: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.deleteManifestBags,
      event,
      '',
      'json'
    );
  }

  public checkManifestExistence(manifestId: string): Observable<any> {
    const url = URLConstants.getManifestById + manifestId;
    return this.baseService.initiateApiRequest('GET', url, '', 'json');
  }

  public setCriteriaDestinationError(data: boolean) {
    this.setCriteriaDestination.next(data);
  }

  public setManifestOriginError(data: boolean) {
    this.setManifestOrigin.next(data);
  }

  public manifestBagCriteriaMatch(bagData: any, criteriaData: any) {
    this.removeEmptyValueKeys(criteriaData);
    return bagData.map((bagInfo: any) => {
      const bagCriteria = bagInfo.criteria;
      if (Object.keys(this.manifestCriteriaFormData).length > 0) {
        const modifiedBagCriteriaData =
          this.getModifiedBagCriteriaData(bagCriteria);
        this.updateBagCriteriaFormData(modifiedBagCriteriaData);
        const criteriaMatch = this.checkForCriteriaMatch(
          this.bagCriteriaFormData,
          this.manifestCriteriaFormData
        );
        return criteriaMatch ? this.getBagDataForGrid(bagInfo) : {};
      } else {
        return this.getBagDataForGrid(bagInfo);
      }
    });
  }

  private getModifiedBagCriteriaData(bagCriteria: any) {
    return {
      minHawbValue: bagCriteria.minHawbValue,
      maxHawbValue: bagCriteria.maxHawbValue,
      minBagWeight:
        bagCriteria.minBagWeight ?? bagCriteria.minHawbWeight ?? null,
      maxBagWeight:
        bagCriteria.maxBagWeight ?? bagCriteria.maxHawbWeight ?? null,
      clearanceTypeCode:
        bagCriteria.clearanceType ?? bagCriteria.clearanceTypeCode ?? null,
      airlineId: bagCriteria.airlineId,
      serviceTypeGroupCode:
        bagCriteria.svcTypeGroup ?? bagCriteria.serviceTypeGroupCode ?? null,
      customerId: bagCriteria.custId ?? bagCriteria.customerId ?? null,
      facilityTypeId: bagCriteria.facilityTypeId ?? null,
      hawbFinalDestinationId:
        bagCriteria.finalDestination ??
        bagCriteria.hawbFinalDestinationId ??
        null,
    };
  }

  private updateBagCriteriaFormData(modifiedBagCriteriaData: any) {
    const keysToFilter = Object.keys(this.manifestCriteriaFormData);
    for (const key in modifiedBagCriteriaData) {
      if (keysToFilter.includes(key)) {
        this.bagCriteriaFormData[key] = modifiedBagCriteriaData[key];
      } else {
        delete this.bagCriteriaFormData[key];
      }
    }
  }

  public bagCriteriaManifestMatchErrorPopup() {
    this.modalService.openPopup(
      {
        message: validationErrorMessages.manifestBagCriteriaMatchError,
        isFooterRequired: false,
      },
      ModalPopupMessages.Warning,
      MessagePopupComponent,
      '360px'
    );
  }

  private removeEmptyValueKeys(obj: IManifestCriteriaData) {
    for (const key in obj) {
      if (criteriaRequiredKeys.includes(key)) {
        this.manifestCriteriaFormData[key] = obj[key];
      }
    }
    for (const key in this.manifestCriteriaFormData) {
      if (
        this.manifestCriteriaFormData[key] === '' ||
        this.manifestCriteriaFormData[key] === 0 ||
        this.manifestCriteriaFormData[key] === null
      ) {
        delete this.manifestCriteriaFormData[key];
      }
    }
  }

  private checkForCriteriaMatch(
    bagCriteriaData: any,
    manifestCriteriaData: IManifestCriteriaData
  ) {
    // Sorting keys using localeCompare for reliable alphabetical order
    const obj1Keys = Object.keys(bagCriteriaData).sort((a, b) =>
      a.localeCompare(b)
    );
    const obj2Keys = Object.keys(manifestCriteriaData).sort((a, b) =>
      a.localeCompare(b)
    );
    // If the number of keys is different, objects cannot be equal
    if (obj1Keys.length !== obj2Keys.length) {
      return false;
    }
    // Compare keys and values
    const areEqual = obj1Keys.every((key, index) => {
      const objKey2 = obj2Keys[index];
      // Convert specific fields to numbers
      if (
        key === 'minBagWeight' ||
        key === 'maxBagWeight' ||
        key === 'minHawbValue' ||
        key === 'maxHawbValue'
      ) {
        bagCriteriaData[key] = +bagCriteriaData[key];
        manifestCriteriaData[objKey2] = +manifestCriteriaData[objKey2];
      }
      const objValue1 = bagCriteriaData[key];
      const objValue2 = manifestCriteriaData[objKey2];
      // Check both keys and values
      return key === objKey2 && objValue1 === objValue2;
    });
    return areEqual;
  }

  private getBagDataForGrid(bagInfo: any) {
    return {
      bagId: bagInfo.bagId,
      bagExt: bagInfo.bagExt,
      pieces: bagInfo.noofpieces,
      weight: bagInfo.totalWeight,
      packagingType: bagInfo.packagingType,
      criteria: bagInfo.criteria,
      isInputRow: false,
      action: CrudActions.create,
    };
  }

  public isManifestSummaryPageInfo(data: string) {
    this.isManifestPageSummary.next(data);
  }

  public setManifestCriteriaForm(data: any) {
    this.isManifestCriteriaFormGroup.next(data);
  }

  public getManifestBulkEditUniqueData(manifestIds: string): Observable<any> {
    const url = URLConstants.uniqueDataBulkEdit + manifestIds;
    return this.baseService.initiateApiRequest('GET', url, '', 'json');
  }

  public getManifestBulkEditCriteriaData(data: any): Observable<any> {
    const url = URLConstants.criteriaDataBulkEdit;
    return this.baseService.initiateApiRequest('POST', url, data, 'json');
  }

  public updateBulkManifest(data: IManifestInformationData): Observable<any> {
    const url = URLConstants.updateBulkManifest;
    return this.baseService.initiateApiRequest('POST', url, data, 'json');
  }

  public advanceManifest(data: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.advanceManifestInformation,
      data,
      'json'
    );
  }
}
