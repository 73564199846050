import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import {
  DropdownList,
  IBagCriteriaStateModel,
  IBagInfoData,
  IBagInformationData,
  IBagMiscellaneousStateModel,
  IBagPieceListData,
  IPieceStateModel,
  bagColumnData,
  defaultBagInfo,
} from 'src/app/features/model/bag.model';
import {
  defaultBagCriteriaData,
  defaultBagMiscellaneousData,
} from '../bag.config';
import {
  AddBagPiece,
  FetchBagsList,
  FetchSelectedBagById,
  LinkPieceToBag,
  ResetBagCriteria,
  ResetBagMiscellaneous,
  ResetBagsInformationList,
  ResetBagsList,
  ResetPieceList,
  SaveBagCriteria,
  SaveBagMiscellaneous,
  UnlinkPieceFromBag,
  UpdateBagInformation,
  DeleteBagPiece,
  FetchBagsInformationListSuccess,
  ResetBagPieceList,
} from './bag.action';

// Criteria Section
@State<IBagCriteriaStateModel>({
  name: 'bagCriteria',
  defaults: {
    criteria: defaultBagCriteriaData(),
  },
})
@Injectable()
export class BagCriteriaState {
  @Action(SaveBagCriteria)
  saveBagCriteriaData(
    ctx: StateContext<IBagCriteriaStateModel>,
    action: SaveBagCriteria
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      criteria: action.payload,
    });
  }

  @Action(ResetBagCriteria)
  ResetBagCriteria(ctx: StateContext<IBagCriteriaStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      criteria: defaultBagCriteriaData(),
    });
  }
}

// Miscellaneous Section
@State<IBagMiscellaneousStateModel>({
  name: 'bagMiscellaneous',
  defaults: {
    miscellaneous: defaultBagMiscellaneousData(),
  },
})
@Injectable()
export class BagMiscellaneousState {
  @Action(SaveBagMiscellaneous)
  saveBagMiscellaneousData(
    ctx: StateContext<IBagMiscellaneousStateModel>,
    action: SaveBagMiscellaneous
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      miscellaneous: action.payload,
    });
  }

  @Action(ResetBagMiscellaneous)
  resetBagMiscellaneousData(ctx: StateContext<IBagMiscellaneousStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      miscellaneous: defaultBagMiscellaneousData(),
    });
  }
}

// Piece table data
@State<IPieceStateModel>({
  name: 'bagPieceList',
  defaults: {
    pieceList: [],
  },
})
@Injectable()
export class BagPieceState {
  @Action(LinkPieceToBag)
  linkPieceData(ctx: StateContext<IPieceStateModel>, action: LinkPieceToBag) {
    const state = ctx.getState();
    const updatedPieceList = [...state.pieceList, ...action.payload]; // Merge old state with new data
    ctx.setState({
      ...state,
      pieceList: updatedPieceList,
    });
  }

  @Action(UnlinkPieceFromBag)
  unlinkPieceData(ctx: StateContext<IPieceStateModel>, { payload }: any) {
    const state = ctx.getState();
    const filteredPieces = state.pieceList.filter(
      linkedPieces => linkedPieces.id !== payload
    );
    ctx.setState({
      ...state,
      pieceList: filteredPieces,
    });
  }

  @Action(ResetPieceList)
  resetBagPieceData(ctx: StateContext<IPieceStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      pieceList: [],
    });
  }
}

export interface BagsListStateModel {
  bags: bagColumnData[];
  totalData: number;
  origins: DropdownList[];
  PackagingType: DropdownList[];
  destinations: DropdownList[];
}

@Injectable()
@State<BagsListStateModel>({
  name: 'bags',
  defaults: {
    bags: [],
    totalData: 0,
    origins: [],
    PackagingType: [],
    destinations: [],
  },
})
export class BagsListState {

  @Action(FetchBagsList)
  FetchBagsList(ctx: StateContext<BagsListStateModel>, action: FetchBagsList) {
    const state = ctx.getState();
    const payload = action.payload;
    if (payload.data && payload.data.bags && payload.data.totalRecords) {
      ctx.setState({
        ...state,
        bags: payload.data.bags,
        totalData: payload.data.totalRecords,
      });
    } else {
      ctx.setState({
        ...state,
        bags: [],
        totalData: 0,
      });
    }
  }

  @Action(ResetBagsList)
  resetBagPieceData(ctx: StateContext<BagsListStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      bags: [],
      totalData: 0,
    });
  }
}

// // Bag Crud
export interface BagInformationStateModel {
  bagInformation: IBagInformationData;
  bagsInformationList: IBagInformationData[];
}
@State<BagInformationStateModel>({
  name: 'bagInformation',
  defaults: {
    bagInformation: {} as IBagInformationData,
    bagsInformationList: [],
  },
})
@Injectable()
export class BagInformationState {

  @Action(FetchBagsInformationListSuccess)
  fetchBagsInformationListSuccess(
    { getState, setState }: StateContext<BagInformationStateModel>,
    { bags }: FetchBagsInformationListSuccess
  ): void {
    const state = getState();
    setState({
      ...state,
      bagsInformationList: bags,
    });
  }

  @Action(FetchSelectedBagById)
  FetchSelectedBagById(
    ctx: StateContext<BagInformationStateModel>,
    action: FetchSelectedBagById
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      bagInformation: state.bagsInformationList.filter(
        element => element.bagId == action.bagId
      )[0],
    });
  }

  @Action(ResetBagsInformationList)
  resetBagInfo(
    ctx: StateContext<BagInformationStateModel>,
    action: ResetBagsInformationList
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      bagInformation: {} as IBagInformationData,
      bagsInformationList: [],
    });
  }
}
export interface BagInfoStateModel {
  bagInfo: IBagInfoData;
}

@State<BagInfoStateModel>({
  name: 'bagInfo',
  defaults: {
    bagInfo: defaultBagInfo,
  },
})
@Injectable()
export class BagInfoState {
  @Action(UpdateBagInformation)
  updateBagInformation(
    ctx: StateContext<BagInfoStateModel>,
    action: UpdateBagInformation
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      bagInfo: action.payload,
    });
  }
}

export interface PieceBagStateModel {
  piecesBag: IBagPieceListData[];
}

@State<PieceBagStateModel>({
  name: 'pieceBag',
  defaults: {
    piecesBag: [],
  },
})
@Injectable()
export class PieceBagState {
  @Action(AddBagPiece)
  addBagPieces(
    ctx: StateContext<PieceBagStateModel>,
    { payload }: AddBagPiece
  ) {
    const state = ctx.getState();
    if (payload && payload.length >= 0) {
      if (payload.length === 0) {
        // If payload is empty, set piecesBag to an empty array
        ctx.setState({
          ...state,
          piecesBag: [],
        });
      } else {
        // If payload is not empty, concatenate it with the existing piecesBag
        ctx.setState({
          ...state,
          piecesBag: [...state.piecesBag, ...payload],
        });
      }
    }
  }

  @Action(DeleteBagPiece)
  deleteBagPiece(
    ctx: StateContext<PieceBagStateModel>,
    { payload }: DeleteBagPiece
  ) {
    const state = ctx.getState();
    const filteredPieces = state.piecesBag.filter(piece =>
      piece.id ? piece.id : piece.piece.id !== payload
    );
    ctx.setState({
      ...state,
      piecesBag: filteredPieces,
    });
  }

  @Action(ResetBagPieceList)
  resetBagPieceData(ctx: StateContext<PieceBagStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      piecesBag: [],
    });
  }
}
