import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { MaterialModule } from '../../material.module';
import { MatTableDataSource } from '@angular/material/table';
import { CommonTableService } from '../../services/common-table.service';
import { CommonService } from '../../services/common.service';
import {
  dateTimeFormat,
  popupButtons,
  ModalPopupMessages,
} from '../../utility/constants';
import { PrintService } from '../../services/print.service';

@Component({
  selector: 'app-information-page-print',
  standalone: true,
  imports: [CommonModule, MaterialModule],
  templateUrl: './information-page-print.component.html',
  styleUrl: './information-page-print.component.scss',
})
export class InformationPagePrintComponent implements OnDestroy {
  public dataSources: { [key: string]: MatTableDataSource<any> } = {};
  public displayedColumns: { [key: string]: string[] } = {};
  public summaryCustomiseTableColumns: { [key: string]: any } = {};
  public isFrom!: string;
  public dateFormat = dateTimeFormat.onlyDate;
  public pageSize = '';
  public popupButtons = popupButtons;
  public isForprint = false;
  public isInfoPagePrintClicked = false;
  public isInfoPagePrintWarning = false;
  public warningMessage = ModalPopupMessages.printWarningContent;
  public objectKeys = Object.keys;
  public infoPageData: any;

  constructor(
    public commonTableService: CommonTableService,
    public commonService: CommonService,
    public printService: PrintService
  ) {
    window.addEventListener('message', this.messageHandler);
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.messageHandler);
  }

  messageHandler = (event: any) => {
    if (event.origin !== window.location.origin) return;
    if (Object.keys(event.data).length) {
      this.infoPageData = event.data.selectedColumns;
      this.getDisplayColumns();
      this.pageSize = event.data.selectedPageSize;
      this.isForprint = event.data.isPrintDialogRequired;
      this.isInfoPagePrintWarning = event.data.isPreviewWarning;
      if (this.isForprint) {
        this.print();
      }
    }
  };

  public print() {
    this.isInfoPagePrintClicked = true;
    window.onafterprint = () => {
      window.close();
    };
    setTimeout(() => {
      window.print();
    }, 0);
  }

  private getDisplayColumns() {
    for (const key in this.infoPageData) {
      if (Object.prototype.hasOwnProperty.call(this.infoPageData, key)) {
        if (this.infoPageData?.[key].data) {
          this.dataSources[key] = new MatTableDataSource(
            this.infoPageData[key].data
          );
          this.displayedColumns[key] = this.infoPageData[key].headerKeys || [];
          this.summaryCustomiseTableColumns[key] =
            this.infoPageData[key].config || [];
        }
      }
    }
  }
}
