import { FormConfig } from 'src/app/shared/models/dynamic.model';

export const advancedSearchManifestFormFieldsConfig: FormConfig = {
  fields: [
    {
      id: 'manifestDate',
      type: 'input',
      label: 'Manifest Date',
    },
    {
      id: 'origin',
      type: 'input',
      label: 'Origin',
    },
    {
      id: 'destination',
      type: 'input',
      label: 'Destination',
    },
    {
      label: 'Receiving Agent',
      id: 'receivingAgent',
      type: 'input',
    },
    {
      id: 'FinalDestination',
      label: 'Final Destination',
      type: 'input',
    },
    {
      type: 'input',
      label: 'Clearance Type',
      id: 'clearanceType',
    },
    {
      id: 'customer',
      type: 'input',
      label: 'Customer',
    },
    {
      id: 'manifestId',
      type: 'input',
      label: 'Manifest Id',
    },
  ],
};

export interface ManifestsModal {
  manifestDate: string;
  origin: string;
  destination: string;
  receivingAgent: string;
  FinalDestination: string;
  clearanceType: string;
  customer: string;
  manifestId: string;
}

export const defaultManifestsModal: ManifestsModal = {
  manifestDate: '',
  origin: '',
  destination: '',
  receivingAgent: '',
  FinalDestination: '',
  clearanceType: '',
  customer: '',
  manifestId: '',
};

export interface ManifestModalStateModel {
  manifestModal: ManifestsModal;
}

// Initial state
export const defaultManifestState: ManifestModalStateModel = {
  manifestModal: defaultManifestsModal,
};
