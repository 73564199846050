import { environment } from '../../../environments/environment';

export const URLConstants = {
  deleteHawb: environment.API_BASE_URL + '/v1/hawb/delete',
  getAccountDetails: environment.API_BASE_URL + '/v1/master/accounts/fetch',
  getCustomerDetails: environment.API_BASE_URL + '/v1/master/customer/fetch/',
  getCountryDetails: environment.API_BASE_URL + '/v1/master/lookup',
  getStateDetails: environment.API_BASE_URL + '/v1/master/locality/search/',
  saveHawbInformation: environment.API_BASE_URL + '/v1/hawb/create',
  updateHawbInformation: environment.API_BASE_URL + '/v1/hawb/update',
  getHawbDataById: environment.API_BASE_URL + '/v1/hawb/fetch/',
  getMasterHawbData:
    environment.API_BASE_URL + '/v1/hawb/masterShipment/validate',
  getDeleteCommodityDataById:
    environment.API_BASE_URL + '/v1/hawb/commodities/delete',
  getSaveCommodityDataById:
    environment.API_BASE_URL + '/v1/hawb/commodities/add',
  getUpdateCommodityDataById:
    environment.API_BASE_URL + '/v1/hawb/commodities/update',
  blockUnblockHawb: environment.API_BASE_URL + '/v1/hawb/blockUnblock',
  addHawbNotes: environment.API_BASE_URL + '/v1/base/addNotes',
  addHawbAttachment: environment.API_BASE_URL + '/v1/hawb/addAttachments',
  addRoles: environment.API_BASE_URL + '/v1/base/roles/create',
  advanceHawbInformation: environment.API_BASE_URL + '/v1/hawb/advanceSearch',

  //Hawb Summary //
  getHawbList: environment.API_BASE_URL + '/v1/hawb/basicSearch',
  getHawbListMock: environment.MOCK_API_URL + '/assets/mock/hawbList.json',
  masterLookup: environment.API_BASE_URL + '/v1/master/lookup/',
  bulkBaggingPieces: environment.API_BASE_URL + '/v1/bag/bulkBagging/validate',
  validateBagExists: environment.API_BASE_URL + '/v1/bag/bagExt/validate',
  createBagForBulkBagging:
    environment.API_BASE_URL + '/v1/bag/bulkBagging/create',
  bulkGatewayValidate:
    environment.API_BASE_URL + '/v1/hawb/bulkGateway/validate',
  BulkGatewayShipments: environment.API_BASE_URL + '/v1/hawb/bulkGateway',

  //Hawb Information //
  getHawbInfoList: environment.API_BASE_URL + '/v1/hawb/search',
  getHawbInfoListMock: environment.MOCK_API_URL + '/assets/mock/hawbInfo.json',
  getHawbById: environment.API_BASE_URL + '/v1/hawb/fetch',
  uniqueHawbDataBulkEdit:
    environment.API_BASE_URL + '/v1/hawb/bulkedit/uniquedata/',
  updateBulkHawb: environment.API_BASE_URL + '/v1/hawb/bulkedit/update',
  validateGateway: environment.API_BASE_URL + '/v1/hawb/gateway/validate',
  gatewayShipment: environment.API_BASE_URL + '/v1/hawb/gateway',

  // Bag Summary //
  getBagList: environment.API_BASE_URL + '/v1/bag/search',
  getBagBasicList: environment.API_BASE_URL + '/v1/bag/basicSearch',

  // Bag Information //
  checkBagExt: environment.API_BASE_URL + '/v1/bag/fetch/',
  getShipmentData: environment.API_BASE_URL + '/v1/bag/getShipments',
  saveBagData: environment.API_BASE_URL + '/v1/bag/create',
  updateBagData: environment.API_BASE_URL + '/v1/bag/update',
  deleteBag: environment.API_BASE_URL + '/v1/bag/delete',
  deleteBagShipment: environment.API_BASE_URL + '/v1/bag/shipment/delete',
  uniqueBagDataBulkEdit:
    environment.API_BASE_URL + '/v1/bag/bulkedit/uniquedata/',
  updateBulkBag: environment.API_BASE_URL + '/v1/bag/bulkedit/update',
  advanceBagInformation: environment.API_BASE_URL + '/v1/bag/advanceSearch',

  // Common Lookup's //
  requestType: environment.API_BASE_URL + '/v1/master/lookup/',

  // Manifest Summary
  getManifestList: environment.API_BASE_URL + '/v1/manifest/search',
  getManifestSearchList: environment.API_BASE_URL + '/v1/manifest/basicSearch',
  deleteManifest: environment.API_BASE_URL + '/v1/manifest/delete',
  getManifestById: environment.API_BASE_URL + '/v1/manifest/fetch/',
  uniqueDataBulkEdit:
    environment.API_BASE_URL + '/v1/manifest/bulkedit/uniquedata/',
  publishManifest: environment.API_BASE_URL + '/v1/manifest/publish',

  // Manifest Information
  getBagsForManifest: environment.API_BASE_URL + '/v1/manifest/getBag',
  createManifest: environment.API_BASE_URL + '/v1/manifest/create',
  updateManifest: environment.API_BASE_URL + '/v1/manifest/update',
  deleteManifestBags: environment.API_BASE_URL + '/v1/manifest/bags/delete',
  updateBulkManifest: environment.API_BASE_URL + '/v1/manifest/bulkedit/update',
  advanceManifestInformation:
    environment.API_BASE_URL + '/v1/manifest/advanceSearch',

  // Attachment
  signedurl: environment.API_BASE_URL + '/v1/attachment/getSignedUrl',
  signedFullManurl: environment.API_BASE_URL + '/v1/attachment/fullmanFile/upload',
  downloadAttachmnet: environment.API_BASE_URL + '/v1/attachment/download',
  deleteAttachment: environment.API_BASE_URL + '/v1/attachment/delete',
  addAttachment: environment.API_BASE_URL + '/v1/attachment/create',
  // Mawb
  checkMawbExists: environment.API_BASE_URL + '/v1/mawb/validate',

  // Flight
  checkFlightExists: environment.API_BASE_URL + '/v1/flight/validate',

  // Mawb Information //
  checkMawbExt: environment.API_BASE_URL + '/v1/mawb/fetch/',
  getManifestData: environment.API_BASE_URL + '/v1/manifest/getManifest/manIds',
  saveMawbData: environment.API_BASE_URL + '/v1/mawb/create',
  updateMawbData: environment.API_BASE_URL + '/v1/mawb/update',
  deleteMawbManifests: environment.API_BASE_URL + '/v1/mawb/deleteManifest',
  addMawbManifests: environment.API_BASE_URL + '/v1/mawb/addManifest',
  deleteFlight: environment.API_BASE_URL + '/v1/mawb/flight/delete',
  getSaveFlightDataById: environment.API_BASE_URL + '/v1/mawb/flight/create',
  getUpdateFlightDataById: environment.API_BASE_URL + '/v1/mawb/flight/update',

  // Mawb Summary
  getMawbList: environment.API_BASE_URL + '/v1/mawb/basicSearch',
  deleteMawb: environment.API_BASE_URL + '/v1/mawb/delete',
  publishMawb: environment.API_BASE_URL + '/v1/mawb/publish',
  fetchMatchedManifests:
    environment.API_BASE_URL + '/v1/mawb/getManifestMatchedCriteria',
  advanceMawbInformation: environment.API_BASE_URL + '/v1/mawb/advanceSearch',

  // Basic Search
  saveUpdatePinSearch: environment.API_BASE_URL + '/v1/search/pinOrUnpin',
  getRecentAndPinnedSearchesList:
    environment.API_BASE_URL + '/v1/search/getRecent',
  getSavedSearchesList: environment.API_BASE_URL + '/v1/search/getSaved',
  saveUpdateNewSearch: environment.API_BASE_URL + '/v1/search/updateSaved',
  deleteSearch: environment.API_BASE_URL + '/v1/search/deleteSaved',

  //auth service
  authorize: environment.API_BASE_URL + "/v1/auth/authorize"
};
