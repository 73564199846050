import { FormConfig } from '../../models/dynamic.model';

/* MAWB - Customize Columns */
export const MawbColumnsList: string[] = [
  'select',
  'mawb',
  'origin',
  'destination',
  'isPublished',
  'agentCode',
  'airlineCode',
  'clearanceType',
  'agentName',
  'airlineName',
  'action',
];

/* MAWB - Manifest Customize Columns */
export const MAWBManifestCustomizeColumns: string[] = [
  'select',
  'manifestNumber',
  'date',
  'bags',
  'shipments',
  'action',
];

/* MAWB - Manifest Customize Columns */
export const MAWBFlightCustomizeColumns: string[] = [
  'select',
  'airlineCode',
  'flightNumber',
  'slacCount',
  'slacWeight',
  'arrivalDate',
  'mawbPart',
  'action',
];

export const AgentColumnList: string[] = [
  'select',
  'agentCode',
  'agentName',
  'agentType',
  'facilities',
  'agentStatus',
  'action',
];

export const AgentFacilityColumnList: string[] = [
  'select',
  'facilityCode',
  'action',
];

export const shipmentCommodityColumnList: string[] = [
  'select',
  'commodityNumber',
  'hsCode',
  'value',
  'description',
  'action',
];

/* Manifest - Bag Customize Columns */
export const ManifestBagsCustomizeColumns: string[] = [
  'select',
  'bagExt',
  'shipments',
  'weight',
  'action',
];

export const bagPieceGridDisplayColumns: string[] = [
  'select',
  'hawbExt',
  'weight',
  'dimensions',
  'shipper',
  'consignee',
  'action',
];

export const selectTableMawbManifestFormfieldsConfig: FormConfig = {
  fields: [
    {
      id: 'searchInput',
      type: 'input',
      label: '',
      minValue: 1,
      maxValue: 25,
      allowNumeric: true,
    },
  ],
};

export const selectTableManifestBagsFormfieldsConfig: FormConfig = {
  fields: [
    {
      id: 'searchInput',
      type: 'input',
      label: '',
      minValue: 3,
      maxValue: 126,
      isCheckForMaxValue: true,
      noInitialWhiteSpace: true,
      allowAlphaNumericWithDotUnderscoreDashSpecialCharacters: true,
    },
  ],
};

export const selectTableBagPieceFormfieldsConfig: FormConfig = {
  fields: [
    {
      id: 'searchInput',
      label: '',
      isCheckForMaxValue: true,
      minValue: 1,
      maxValue: 25,
      noInitialWhiteSpace: true,
      type: 'input',
    },
  ],
};

export const AutocompleteControls = [
  'airlineCode',
  'packagingTypeName',
  'codeAndDescription',
];

export const ValueControls = [
  'commodityNumber',
  'pieceNumber',
  'username',
  'attachments',
  'additionalDetails',
];

export const InputWithDecimalValidationKeys = ['slacWeight', 'value', 'weight'];

export const InputWithoutValidationKeys = [
  'slacCount',
  'mawbPart',
  'hsCode',
  'description',
  'additionalInformation',
];

export const InputWithAlphaNumbers = ['flightNumber'];

export const AccountColumnList: string[] = [
  'select',
  'accountNumber',
  'accountName',
  'subAccountCount',
  'organizationName',
  'shipmentCount',
  'additionalInfo',
  'action',
];

export const SubAccountColumnList: string[] = [
  'select',
  'subAccountName',
  'subAccountNumber',
  'description',
  'additionalInformation',
  'action',
];
export const InputWithAlphaNumbersHyphen = ['pieceExt'];

export const InputWithNumbers = [
  'dimLWH',
  'dimL',
  'dimW',
  'dimH',
  'subAccountNumber',
  'subAccountName',
];

export const TableControls = {
  airlineCode: 'airlineCode',
  packageType: 'packagingTypeName',
  arrivalDate: 'arrivalDate',
  dimLWH: 'dimLWH',
  dateTimeAndTimezone: 'dateTimeAndTimezone',
  codeAndDescription: 'codeAndDescription',
};

export const ApiControls = {
  airlineCode: 'airlineId',
  packageType: 'packagingType',
  codeAndDescription: 'statusCodes',
};

export const desiredPackageIds = ['B', 'C', 'L', 'M', 'P', 'O', 'A', 'T'];

export const ShipmentGatewayPieceColumnList: string[] = [
  'shipmentGatewayShipment',
  'shipmentGatewayConsigneeName',
  'shipmentGatewayOrigin',
  'shipmentGatewayFinalDestination',
  'shipmentGatewayDateOfCreation',
  'shipmentGatewayWeights',
  'shipmentGatewayDimensions',
  'isGatewayed',
  'actions',
];

export const statusCodeColumnList: string[] = [
  'select',
  'dateTimeAndTimezone',
  'username',
  'codeAndDescription',
  'attachments',
  'additionalDetails',
];

export const smStatusCodesColumnList: string[] = [
  'select',
  'statusCodeType',
  'statusCode',
  'applicableFor',
  'description',
  'action',
];

export const smRolesAndPermissionsColumnList: string[] = [
  'select',
  'group',
  'department',
  'role',
  'facility',
  'user',
  'information',
  'action',
];

export const gatewayMasterShipmentColumnList: string[] = [
  'masterShipment',
  'masterShipmentConsigneeName',
  'masterShipmentOrigin',
  'masterShipmentFinalDestination',
  'masterShipmentDateOfCreation',
  'isMasterShipmentGatewayed',
  'actions',
];

export const ShipmentBagColumnList: string[] = [
  'radioSelect',
  'hawbExt',
  'accountDetails',
  'consignee',
  'origin',
  'destination',
  'weight',
  'dimension',
];
