import { Injectable } from '@angular/core';
import { State, Action, Store } from '@ngxs/store';
import { bagInfoResetStateModel } from 'src/app/features/model/bag.model';
import { defaultBagInformationData } from '../bag.config';
import { ResetEvents } from 'src/app/shared/components/events/store/events.action';
import {
  ResetBagCriteria,
  ResetBagMiscellaneous,
  ResetPieceList,
} from './bag.action';
import { ResetNotesSection } from 'src/app/shared/components/notes/store/notes.action';
import { ResetAtatchmnetCodes } from 'src/app/shared/components/attachments/store/attachments.action';

// Reset action
export class BagResetAllStates {
  static readonly type = '[Bag] Reset All States';
}

export class ResetSharedStates {
  static readonly type = '[Global] Reset Shared States';
}

@State<bagInfoResetStateModel>({
  name: 'bagResetStates',
  defaults: {
    data: defaultBagInformationData(),
  },
})
@Injectable()
export class BagResetAllState {
  constructor(private readonly store: Store) {}

  @Action(BagResetAllStates)
  resetAllStates() {
    this.store.dispatch(new ResetEvents());
    this.store.dispatch(new ResetPieceList());
    this.store.dispatch(new ResetBagMiscellaneous());
    this.store.dispatch(new ResetBagCriteria());
  }

  @Action(ResetSharedStates)
  clearSharedStates() {
    this.store.dispatch(new ResetNotesSection());
    this.store.dispatch(new ResetAtatchmnetCodes());
  }
}
