<div class="summaryPage-Preview-Window">
  <div
    *ngIf="isPrintWarning"
    class="print-warning-text {{
      isPrintClicked ? 'hide-warningText' : 'show-warningText'
    }}">
    {{ warningMessage }}
  </div>
  <div class="print-page {{ pageSize }}">
    <div class="logo" title="PACTrak">
      <img src="assets/Logo.svg" alt="" />
    </div>

    <mat-table [dataSource]="dataSource" class="ibc-print-table">
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <ng-container>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </ng-container>

      <ng-container
        *ngFor="let item of summaryCustomiseTableColumns; let i = index">
        <ng-container
          [matColumnDef]="item.key"
          *ngIf="
            commonTableService.displaySelectedColumnsToTable(
              item.key,
              summaryCustomiseTableColumns,
              displayedColumns
            )
          ">
          <mat-header-cell
            *matHeaderCellDef
            [style.flex]="'0 0 ' + item.defaultPrintWidth + 'px'">
            <span class="ibc-body-text-large">{{ item.label }}</span>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            [style.flex]="'0 0 ' + item.defaultPrintWidth + 'px'">
            <span
              class="{{
                commonTableService.isHyperlink(item.label, isFrom)
                  ? 'text-hyperlink'
                  : ''
              }}">
              {{
                item.key !== 'date'
                  ? element[item.key]
                  : (element.date | date: dateFormat)
              }}
            </span>
          </mat-cell>
        </ng-container>
      </ng-container>
    </mat-table>
  </div>
  <button
    class="ibc-medium-button ibc-primary-default-button cursor preview-print-btn {{
      isPrintClicked ? 'hide-btn' : 'show-btn'
    }}"
    (click)="print()">
    <span class="ibc-body-text-regular">
      {{ popupButtons.Print }}
    </span>
  </button>
</div>
