import { FormConfig } from '../../models/dynamic.model';

export const bagsCriteriaFormFieldsConfig: FormConfig = {
  fields: [
    {
      id: 'agent',
      type: 'input',
      label: 'Agent',
      allowAlphabet: true,
    },
    {
      id: 'shipmentFinalDestination',
      type: 'input',
      label: 'Shipment Final Destination',
      allowAlphabet: true,
    },
    {
      id: 'facilityType',
      type: 'input',
      label: 'Facility Type',
      allowAlphabet: true,
    },
    {
      id: 'airline',
      type: 'input',
      label: 'Airline',
      allowAlphabet: true,
    },
    {
      id: 'clearanceType',
      type: 'input',
      label: 'Clearance Type',
      allowAlphabet: true,
    },
    {
      id: 'serviceTypeGroup',
      type: 'input',
      label: 'Service Type Group',
      allowAlphabet: true,
    },
    {
      id: 'objectGroup',
      type: 'input',
      label: 'Object Group',
      allowAlphabet: true,
    },
    {
      id: 'customer',
      type: 'input',
      label: 'Customer',
      allowAlphabet: true,
    },
    {
      id: 'minBagWeight',
      type: 'input',
      label: 'Min Bag Weight (lb)',
      allowNumeric: true,
    },
    {
      id: 'maxBagWeight',
      type: 'input',
      label: 'Max Bag Weight (lb)',
      allowNumeric: true,
    },
    {
      id: 'minShipmentValue',
      type: 'input',
      label: 'Min Shipment Value ($)',
      allowNumeric: true,
    },
    {
      id: 'maxShipmentValue',
      type: 'input',
      label: 'Max Shipment Value ($)',
      allowNumeric: true,
    },
  ],
};

export interface BagsCriteriaModal {
  agent: string;
  shipmentFinalDestination: string;
  facilityType: string;
  airline: string;
  FinalDestination: string;
  clearanceType: string;
  serviceTypeGroup: string;
  objectGroup: string;
  customer: string;
  minBagWeight: string;
  maxBagWeight: string;
  minShipmentValue: string;
  maxShipmentValue: string;
}

export const defaultBagsCriteriaModal: BagsCriteriaModal = {
  agent: '',
  shipmentFinalDestination: '',
  facilityType: '',
  airline: '',
  FinalDestination: '',
  clearanceType: '',
  serviceTypeGroup: '',
  objectGroup: '',
  customer: '',
  minBagWeight: '',
  maxBagWeight: '',
  minShipmentValue: '',
  maxShipmentValue: '',
};

export interface BagCriteriaModalStateModel {
  detailCriteriaModal: BagsCriteriaModal;
}

// Initial state
export const defaultBagCriteriaState: BagCriteriaModalStateModel = {
  detailCriteriaModal: defaultBagsCriteriaModal,
};
