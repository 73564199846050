import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Observable, Subject, map, startWith, takeUntil } from 'rxjs';
import {
  FormConfig,
  IDropdownResponse,
  ILookupDropdownResponse,
} from 'src/app/shared/models/dynamic.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { ValidatorService } from 'src/app/shared/services/form-control-validators.service';
import {
  ActionIcons,
  MasterLookup,
  ModeIcons,
  pageType,
  SvgIconFrom,
  validationErrorMessages,
} from 'src/app/shared/utility/constants';
import { manifestCriteriaFormfieldsConfig } from '../../manifest.config';
import { SaveManifestCriteria } from '../../store/manifest.action';
import {
  IManifestCriteriaData,
  IManifestDetailsData,
} from 'src/app/features/model/manifest.model';
import { ManifestService } from 'src/app/features/service/manifest.service';

@Component({
  selector: 'app-criteria',
  templateUrl: './criteria.component.html',
  styleUrl: './criteria.component.scss',
})
export class CriteriaComponent implements OnInit, OnDestroy {
  public manifestCriteriaInfoForm!: FormGroup;
  public criteriaFieldsJsonConfig!: FormConfig;
  public clearanceTypeData!: IDropdownResponse[];
  public filteredClearanceTypeOptions!:
    | Observable<IDropdownResponse[]>
    | undefined;
  public airlineCodeData!: any;
  public filteredAirlineCodeOptions!:
    | Observable<IDropdownResponse[]>
    | undefined;
  public serviceTypeData!: IDropdownResponse[];
  public filteredServiceTypeOptions!:
    | Observable<IDropdownResponse[]>
    | undefined;
  public customerData!: any;
  public filteredCustomerOptions!: Observable<IDropdownResponse[]> | undefined;
  public objectGroupNameData!: IDropdownResponse[];
  public filteredObjectGroupNameOptions!:
    | Observable<IDropdownResponse[]>
    | undefined;
  public facilityData!: IDropdownResponse[];
  public filteredFacilityOptions!: Observable<IDropdownResponse[]> | undefined;
  public hawbFinalDestinationData!: IDropdownResponse[];
  public filteredHawbFinalDestinationOptions!:
    | Observable<IDropdownResponse[]>
    | undefined;
  public shipmentDestinationError = false;
  public maxBagWeightError = false;
  public minBagWeightError = false;
  public maxHawbValueError = false;
  public minHawbValueError = false;
  public orgdestError = validationErrorMessages.manifestOriginDestination;
  public minmaxHawbErrorMessage = validationErrorMessages.minmaxHawbValue;
  public minmaxBagWeightErrorMessage = validationErrorMessages.minmaxBagWeight;
  public setManifestCriteriaWidth: any = {};
  public setManifestCriteriaErrorTooltip: any = {};
  public setManifestCriteriaTooltipEvent: any = {};
  public manifestDetailsSectionData$!: Observable<IManifestDetailsData>;
  public criteriaSectionData$!: Observable<IManifestCriteriaData>;
  public manifestOriginData = '';
  public pageMode!: string;
  public actionIconsList = ActionIcons;
  public svgIconFromList = SvgIconFrom;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private initialDataBind = false;

  constructor(
    private readonly manifestService: ManifestService,
    private readonly formUtilsService: ValidatorService,
    public commonService: CommonService,
    private readonly store: Store
  ) {
    this.setRequiredInitialisation();
  }

  ngOnInit(): void {
    this.criteriaFieldsJsonConfig = manifestCriteriaFormfieldsConfig;
    this.createForm();
    this.getManifestDetailsData();
    this.checkPageMode();
    this.checkForManifestCriteriaPageSidebarExpanded();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public createForm() {
    const { form } = this.formUtilsService.generateForm(
      this.criteriaFieldsJsonConfig
    );
    this.manifestCriteriaInfoForm = form;
    this.manifestService.setManifestCriteriaForm(this.manifestCriteriaInfoForm);
  }

  public checkControlHasError(controlName: string) {
    return this.commonService.checkControlError(
      this.manifestCriteriaInfoForm,
      controlName
    );
  }

  public fetchWidth(event: MouseEvent, fieldName: string) {
    this.commonService.setErrorTooltipData(
      event,
      fieldName,
      this.setManifestCriteriaWidth,
      this.setManifestCriteriaTooltipEvent,
      this.setManifestCriteriaErrorTooltip
    );
  }

  public checkMinMaxHawbValues(controlName: string) {
    const min = +this.manifestCriteriaInfoForm.get(MasterLookup.minHawbValue)
      ?.value;
    const max = +this.manifestCriteriaInfoForm.get(MasterLookup.maxHawbValue)
      ?.value;
    const comparisonError = this.commonService.comparisonError(
      min,
      max,
      MasterLookup.maxHawbValue,
      controlName,
      false,
      this.manifestCriteriaInfoForm,
      MasterLookup.minHawbValue
    );
    this.maxHawbValueError = comparisonError.control1Error;
    this.minHawbValueError = comparisonError.control2Error;
  }

  public checkMinMaxBagWeights(controlName: string) {
    const min = +this.manifestCriteriaInfoForm.get(MasterLookup.minBagWeight)
      ?.value;
    const max = +this.manifestCriteriaInfoForm.get(MasterLookup.maxBagWeight)
      ?.value;
    const comparisonError = this.commonService.comparisonError(
      min,
      max,
      MasterLookup.maxBagWeight,
      controlName,
      false,
      this.manifestCriteriaInfoForm,
      MasterLookup.minBagWeight
    );
    this.maxBagWeightError = comparisonError.control1Error;
    this.minBagWeightError = comparisonError.control2Error;
  }

  /* To Check Origin and Destination Match */
  public checkOriginAndShipmentFinalDestination(controlName: string) {
    const destination = this.manifestCriteriaInfoForm.get(
      MasterLookup.hawbFinalDestination
    )?.value;
    const comparisonError = this.commonService.comparisonError(
      this.manifestOriginData,
      destination,
      'originName',
      controlName,
      true,
      this.manifestCriteriaInfoForm,
      MasterLookup.hawbFinalDestination
    );
    this.manifestService.setManifestOriginError(comparisonError.control1Error);
    this.shipmentDestinationError = comparisonError.control2Error;
  }

  public getErrorMessage(controlName: string): string {
    if (
      this.manifestCriteriaInfoForm.get(controlName)?.errors?.[
        'autocompleteError'
      ]
    ) {
      return validationErrorMessages.autocompleteError;
    } else {
      return '';
    }
  }

  public formAutocompleteValueChanges(
    controlName: string,
    apiControlName: string,
    dropdownResponse: any
  ) {
    this.commonService.getAutocompleteDropdownId(
      dropdownResponse,
      this.manifestCriteriaInfoForm,
      controlName,
      apiControlName
    );
    this.dispatchFormValues();
  }

  public dispatchFormValues() {
    this.commonService.checkPristineAccordionData(
      this.manifestCriteriaInfoForm.pristine
    );
    this.setDirtyValidProp();
    this.store.dispatch(
      new SaveManifestCriteria(this.manifestCriteriaInfoForm.value)
    );
  }

  private getManifestCriteriaValidity() {
    return (
      !this.shipmentDestinationError &&
      !this.minBagWeightError &&
      !this.maxBagWeightError &&
      !this.minHawbValueError &&
      !this.maxHawbValueError &&
      this.manifestCriteriaInfoForm.valid
    );
  }

  private setRequiredInitialisation() {
    this.loadInitialApi();
    this.manifestDetailsSectionData$ = this.store.select(
      state => state.manifestInfo
    );
    this.manifestService.setCriteriaDestination
      .pipe(takeUntil(this.destroy$))
      .subscribe((destErr: boolean) => {
        this.shipmentDestinationError = destErr;
      });
    this.criteriaSectionData$ = this.store.select(
      state => state.manifestCriteria
    );
  }

  private async loadInitialApi() {
    const [
      clearanceTypeData,
      airlineCodeData,
      serviceTypeData,
      customerData,
      facilityData,
      hawbFinalDestinationData,
    ]: any = await Promise.all([
      this.getManifestMasterLookUps(MasterLookup.clearanceType),
      this.getManifestMasterLookUps(MasterLookup.airlineCode),
      this.getManifestMasterLookUps(MasterLookup.serviceTypeGroups),
      this.getManifestMasterLookUps(MasterLookup.customerId),
      this.getManifestMasterLookUps(MasterLookup.origin),
      this.getManifestMasterLookUps(MasterLookup.destination),
    ]);
    this.clearanceTypeData = clearanceTypeData;
    this.filteredClearanceTypeOptions = this.autocompleteIdValueChanges(
      'clearanceType',
      this.clearanceTypeData
    );
    this.airlineCodeData = airlineCodeData;
    this.airlineCodeData.forEach((airline: any) => {
      airline.name = `${airline.code} ${airline.name}`;
    });
    this.filteredAirlineCodeOptions = this.autocompleteValueChanges(
      'airline',
      this.airlineCodeData
    );
    this.serviceTypeData = serviceTypeData;
    this.serviceTypeData.forEach((service: any) => {
      service.name = `${service.id} - ${service.name}`;
    });
    this.filteredServiceTypeOptions = this.autocompleteIdValueChanges(
      'serviceTypeGroup',
      this.serviceTypeData
    );
    this.customerData = customerData;
    this.customerData.forEach((customer: any) => {
      customer.name = `${customer.accnt_id} - ${customer.name}`;
    });
    this.filteredCustomerOptions = this.autocompleteValueChanges(
      'customer',
      this.customerData
    );
    this.facilityData = facilityData;
    this.filteredFacilityOptions = this.autocompleteValueChanges(
      'facilityType',
      this.facilityData
    );
    this.hawbFinalDestinationData = hawbFinalDestinationData;
    this.filteredHawbFinalDestinationOptions = this.autocompleteValueChanges(
      MasterLookup.hawbFinalDestination,
      this.hawbFinalDestinationData
    );
    this.dataFetch();
  }

  private getManifestMasterLookUps(requestType: string) {
    return new Promise((resolve, reject) => {
      try {
        this.commonService
          .getMasterLookUp(requestType)
          .subscribe((response: ILookupDropdownResponse) => {
            if (response?.data) {
              resolve(response.data);
            } else {
              resolve([]);
            }
          });
      } catch (error) {
        reject(new Error(''));
      }
    });
  }

  /* Set filter event based on value changes */
  private autocompleteValueChanges(
    controlName: string,
    dropdownData: IDropdownResponse[]
  ) {
    const formControl = this.manifestCriteriaInfoForm.get(
      controlName
    ) as FormControl;
    return formControl.valueChanges.pipe(
      startWith(''),
      map(val => {
        const filterValue = typeof val === 'string' ? val.toLowerCase() : '';
        return dropdownData.filter(item =>
          item.name.toLowerCase().includes(filterValue)
        );
      })
    );
  }

  private autocompleteIdValueChanges(
    controlName: string,
    dropdownData: IDropdownResponse[]
  ) {
    const formControl = this.manifestCriteriaInfoForm.get(
      controlName
    ) as FormControl;
    return formControl.valueChanges.pipe(
      startWith(''),
      map((val: any) => {
        const filterValue = typeof val === 'string' ? val.toLowerCase() : '';
        return dropdownData.filter(item =>
          item.id.toLowerCase().includes(filterValue)
        );
      })
    );
  }

  private getManifestDetailsData() {
    this.manifestDetailsSectionData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.manifestOriginData = data.manifestInfo.originName;
      });
  }

  private checkPageMode() {
    this.commonService.isCheckForMode
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data) {
          this.pageMode = data;
          if (this.pageMode == ModeIcons.view) {
            this.manifestCriteriaInfoForm?.disable();
          } else {
            this.manifestCriteriaInfoForm?.enable();
          }
        }
      });
  }

  private dataFetch() {
    this.commonService.savedBagData
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data === pageType.dataBind) {
          this.initialDataBind = true;
          this.getCriteriaData();
        }
      });
  }

  private getCriteriaData() {
    this.criteriaSectionData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (this.initialDataBind) {
          this.initialDataBind = false;
          this.manifestCriteriaInfoForm
            .get('clearanceType')
            ?.setValue(data.criteria.clearanceTypeCode);
          this.manifestCriteriaInfoForm
            .get('serviceTypeGroup')
            ?.setValue(data.criteria.serviceTypeGroupCode);
          this.manifestCriteriaInfoForm.patchValue(data.criteria);
          this.commonService.getAutocompleteDropdownId(
            this.hawbFinalDestinationData,
            this.manifestCriteriaInfoForm,
            MasterLookup.hawbFinalDestination,
            MasterLookup.hawbFinalDestinationId,
            true
          );
          this.commonService.getAutocompleteDropdownId(
            this.facilityData,
            this.manifestCriteriaInfoForm,
            'facilityType',
            'facilityTypeId',
            true
          );
          this.commonService.getAutocompleteDropdownId(
            this.airlineCodeData,
            this.manifestCriteriaInfoForm,
            'airline',
            'airlineId',
            true
          );
          this.commonService.getAutocompleteDropdownId(
            this.customerData,
            this.manifestCriteriaInfoForm,
            'customer',
            'customerId',
            true
          );
          this.commonService.getAutocompleteDropdownId(
            this.serviceTypeData,
            this.manifestCriteriaInfoForm,
            'serviceTypeGroup',
            'serviceTypeGroupCode',
            true
          );
          this.setDirtyValidProp();
        }
      });
    this.store.dispatch(
      new SaveManifestCriteria(this.manifestCriteriaInfoForm.value)
    );
  }

  private setDirtyValidProp() {
    this.manifestCriteriaInfoForm
      .get('isDirty')
      ?.setValue(this.manifestCriteriaInfoForm.dirty);
    this.manifestCriteriaInfoForm
      .get('isValid')
      ?.setValue(this.getManifestCriteriaValidity());
  }

  private checkForManifestCriteriaPageSidebarExpanded() {
    this.commonService.isMenuExpanded.subscribe(() => {
      this.setManifestCriteriaWidth =
        this.commonService.setModifiedTooltipWidth(
          this.setManifestCriteriaTooltipEvent,
          this.setManifestCriteriaErrorTooltip,
          this.setManifestCriteriaWidth
        );
    });
  }
}
