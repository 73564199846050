<div class="infoPage-Preview-Window">
  <div
    *ngIf="isInfoPagePrintWarning"
    class="infoPagePrintWarningText {{
      isInfoPagePrintClicked ? 'hide-warningText' : 'show-warningText'
    }}">
    {{ warningMessage }}
  </div>

  <div *ngIf="infoPageData" class="print-page {{ pageSize }}">
    <div class="logo" title="PACTrak">
      <img src="assets/Logo.svg" alt="" />
    </div>

    <ng-container *ngFor="let sectionKey of objectKeys(infoPageData)">
      <div class="section-header ibc-heading-h6-small-bold">
        {{ printService.getHeaderSectionKeys(sectionKey) }}
      </div>

      <mat-table
        *ngIf="dataSources"
        [dataSource]="dataSources[sectionKey]"
        class="ibc-print-table">
        <mat-header-row
          *matHeaderRowDef="displayedColumns[sectionKey]"></mat-header-row>
        <ng-container>
          <mat-row
            *matRowDef="
              let row;
              columns: displayedColumns[sectionKey]
            "></mat-row>
        </ng-container>

        <ng-container
          *ngFor="
            let item of summaryCustomiseTableColumns[sectionKey];
            let i = index
          ">
          <ng-container
            [matColumnDef]="item.key"
            *ngIf="
              commonTableService.displaySelectedColumnsToTable(
                item.key,
                summaryCustomiseTableColumns[sectionKey],
                displayedColumns[sectionKey]
              )
            ">
            <mat-header-cell
              *matHeaderCellDef
              [style.flex]="'0 0 ' + item.defaultPrintWidth + 'px'">
              <span class="ibc-body-text-large-bold">{{ item.label }}</span>
            </mat-header-cell>
            <mat-cell
              *matCellDef="let element"
              [style.flex]="'0 0 ' + item.defaultPrintWidth + 'px'">
              <span
                class="ibc-body-text-regular {{
                  commonTableService.isHyperlink(item.label, isFrom)
                    ? 'text-hyperlink'
                    : ''
                }}">
                {{
                  item.key !== 'date'
                    ? element[item.key]
                    : (element.date | date: dateFormat)
                }}
              </span>
            </mat-cell>
          </ng-container>
        </ng-container>
      </mat-table>
    </ng-container>
  </div>

  <button
    class="ibc-medium-button ibc-primary-default-button cursor infoPage-preview-print-btn {{
      isInfoPagePrintClicked ? 'hide-btn' : 'show-btn'
    }}"
    (click)="print()">
    <span class="ibc-body-text-regular">
      {{ popupButtons.Print }}
    </span>
  </button>
</div>
